import { createAsyncThunk } from "@reduxjs/toolkit";
import { z } from "zod";

import API, { showNetworkErrorToast } from "Utils/api";
import { RootState } from "../..";

import { getCurrentSemanticMapId } from "../appState";

export const fetchMissionAssets = createAsyncThunk(
  "operatorMissionAssets/fetchMissionAssets",
  async (_, thunkAPI) => {
    try {
      const semanticMapId = getCurrentSemanticMapId(
        thunkAPI.getState() as RootState
      );
      const response = await API.get(
        `/missions/operator_flow/?semantic_map=${semanticMapId}`
      );
      return MissionPlansValidator.parse(response.data.results);
    } catch (error) {
      showNetworkErrorToast(error);
      return Promise.reject();
    }
  }
);

const MissionStopValidator = z.object({
  id: z.string(),
  duration: z.number(),
  order: z.number(),
  stopPk: z.string(),
  hitchType: z.number(),
});

const MissionPlanValidator = z.object({
  id: z.string(),
  loops: z.number().optional(),
  name: z.string(),
  previous: z.string(),
  semanticMap: z.string(),
  stops: z.array(MissionStopValidator).nonempty(),
  timeStamp: z.string().nullable(),
  updatedAt: z.string(),
});

export const MissionPlansValidator = z.array(MissionPlanValidator);
